





















































import { Component, PropSync, Vue } from 'vue-property-decorator';

import AllianzWithdrawalScheduleViewModel
  from '@/vue-app/view-models/allianz-dashboard/withdrawals/allianz-withdrawal-schedule-view-model';

@Component({ name: 'AllianzWithdrawalSchedule' })
export default class AllianzWithdrawalSchedule extends Vue {
  @PropSync('loading', { type: Boolean, required: true })
  synced_loading!: boolean;

  allianz_withdrawal_schedule_view_model = Vue.observable(
    new AllianzWithdrawalScheduleViewModel(this),
  )
}

